<template>
  <a-card> 欢迎使用管理系统 </a-card>
  <a-card title="测试store"> {{ userStore.store }} </a-card>
  <TestStore />
  <a-card>
    <a-button @click="login">LOGIN</a-button>
    <a-button @click="changeName">CHANGE NAME</a-button>
    <a-button @click="getToken">GET TOKEN</a-button>
    <a-button @click="sendNet">测试请求</a-button>
  </a-card>
  <!-- <TestGMVue /> -->
</template>

<script setup>
import { HANDLE, equipAPI } from "@/Api/index.js";
import { useUserStore } from "@/store/user.js";
import TestStore from "./TestStore";
import { getToken } from "./TestJS";
// import TestGMVue from "./TestGM.vue";
const userStore = useUserStore();

const sendNet = () => {
  HANDLE.fullLoading(
    equipAPI
      .equipList({ compid: "01" })
      .then(HANDLE.handleSuccess, HANDLE.handleError)
  );
};

const login = async () => {
  console.log("login befor");
  await userStore.login({ username: "AAAAA", password: "password" });
  console.log("login after");
};
const changeName = () => {
  userStore.store.user.name = "BBBBBBB";
};
</script>

<style scoped></style>
